import React, { useState, useEffect } from 'react';
import { Tabs,Button,Row,Col,Space } from 'antd';
import MapComponent from './MapComponent';
import DiscoverComponent from './DiscoverComponent';
import {MdClose} from 'react-icons/md'

export default function DiscoverScreen({ParentCallback,newUnitSceneData,userId}){
  const[activeButton, setActiveButton] = useState('1');  

    const onChange = (key) => {
    setActiveButton(key);
        console.log(key);
      };

      const MapCall =(data)=>{
ParentCallback(data);
        console.log(data);

      }

      const handleCloseMap=()=>{
        ParentCallback("closeMap");
      }
  
      
    return(
        <div style={{background:"#161616",zIndex:"100",position:"relative",height:"100vh",width:"100vw",overflowX:"hidden"}}>


          
     <Row className="discoverTopRow">
<Col  xs={3} lg={3} md={3}>
<Space size='small' direction='vertical'>


     
    

</Space>
</Col>

<Col  xs={18} lg={18} md={18} style={{textAlign:"center"}}>
<Space size='small' direction='horizontal'>


{activeButton == "1"?(<>
  <Button onClick={()=>onChange('1')} className='activeButtonDiscover' >
          MAP
 </Button>
      </>):(<>
      
        <Button onClick={()=>onChange('1')}  className='ButtonDiscover'>
          MAP
 </Button>
      </>)}

      {activeButton == "2"?(<>
  <Button onClick={()=>onChange('2')} className='activeButtonDiscover'>
  DISCOVER
 </Button>
      </>):(<>
      
        <Button onClick={()=>onChange('2')} className='ButtonDiscover'> 
        DISCOVER
 </Button>
      </>)}





</Space>
</Col>


<Col  xs={3} lg={3} md={3} style={{textAlign:"end"}}>
<Space size='small' direction='vertical'>

<Button onClick={()=>handleCloseMap()} style={{width:'100%',backgroundColor:"transparent",height:"50px", border:"none"}}  >
<MdClose style={{color:"#ffffff",fontSize:"32px"}} />



 </Button>

</Space>
</Col>

     </Row>



     <div className='activeComp'>
      {activeButton == "1"?(<>
        <MapComponent ParentCallback={MapCall}/>
      </>):(<>
      
        <DiscoverComponent ParentCallback={MapCall} newUnitSceneData={newUnitSceneData} userId={userId}/>
      </>)}
      </div>

     
        </div>
    )
}