import { AvatarCreator } from "@readyplayerme/react-avatar-creator"
import {MdClose} from 'react-icons/md'
import { Button } from "antd";
var isMobile = require('detect-touch-device');
const config = {
  clearCache: true,
  bodyType: "fullbody",
  quickStart: false,
  language: "en",
}

const mobileStyle = {height: "92vh", width:"100vw", border: "none",position:"fixed",top:0,bottom:0, zindex:"100" }
const DeskStyle = {height: "100vh", width:"100vw", border: "none",position:"fixed",top:0,bottom:0, zindex:"100" }
function ReadyPlayerMeUI({parentCallback}) {
  const handleOnAvatarExported = event => {
    console.log(`Avatar URL is: ${event.data.url}`)
    parentCallback(event.data.url);
  }

  return (
    <>

<Button onClick={()=>parentCallback("close")} className="readyPlayerClose" ><MdClose style={{fontSize:"24px",color:"#fff"}}/></Button>

         {isMobile.isMobile == true?(
          <AvatarCreator
        subdomain="infinity-void-metaverse"
        config={config}
        style={mobileStyle}
        onAvatarExported={handleOnAvatarExported}
      />
         ):(<>

<AvatarCreator
        subdomain="infinity-void-metaverse"
        config={config}
        style={DeskStyle}
        onAvatarExported={handleOnAvatarExported}
      >


      </AvatarCreator>
      </>
         )

         }
      
    </>
  )
}

export default ReadyPlayerMeUI;
