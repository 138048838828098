import React, { useState,useEffect } from 'react';
import { Card, Col, Row, Typography  } from 'antd';

import { Button, Modal } from 'antd';


import {IoMdChatbubbles, IoIosMic, IoIosMicOff, IoMdContract} from "react-icons/io";
import {HiUsers} from "react-icons/hi";
import {IoLocationSharp}   from "react-icons/io5"
import {BsFillVolumeUpFill} from "react-icons/bs";
import {GoScreenFull} from "react-icons/go";
import {HiSpeakerXMark} from  "react-icons/hi2";

import {FaArrowRightToCity} from "react-icons/fa6";


import "./newGameBox.css"

import Lottie from 'react-lottie';
import animationData from './animation_loe0wh6d.json';


import Circle from './imgs/circle.png'
import Person from './imgs/person.png'
import Tap from './imgs/tap.png'


const { Title, Text, } = Typography;

function HelpUI() {


  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
  



  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };






    const data = [
      { title: 'Mute/Unmute Microphone',
      icon: <IoIosMic style={{ color: "black", fontSize: "20px", }} />,
     },
      { title: 'Participants', 
      icon:  <HiUsers style={{ color:"black",fontSize: "20px",}} />,   
     },
      { title: 'Toggle Map',
      icon: <IoLocationSharp style={{ color:"black",fontSize: "20px",}} />,
      },
   
      { title: 'Mute/Unmute Volume',
      icon: <BsFillVolumeUpFill    style={{ color:"black",fontSize: "20px",}} />,   
     },
        { title: 'Toggle Fullscreen',
        icon: <GoScreenFull  style={{ color:"black",fontSize: "20px",}} />,
         },
        { title: 'Hide/Unhide Messages',
        icon: <IoMdChatbubbles  style={{ color:"black",fontSize: "20px",}} />,
        },
       
       { title: 'Return to city',
       icon: <FaArrowRightToCity  style={{ color:"black",fontSize: "20px",}} />,
      },
   
    ]



    const isLandscape2 = window.matchMedia('(orientation: landscape)').matches;

   



 
  
    
   useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
 
    }, []);



    return (
        <>
            
          <div style={{background:"#fff"}}>
      
       <Row   justify="center" >
       
       {screenWidth <= 450  ? (<>
   
<h1 style={{ fontWeight: 'bold'}}>Controls and Tips</h1>
     </>):(
        <>
        
<Text style={{fontSize:"35px", fontWeight: 'bold', marginTop:"12px"}}>  Controls and Tips </Text>

        </>
      )}
       </Row>
       

       <Row justify="left"  >
     
<Text style={{fontSize:"20px", fontWeight: 'bold'}}>  Navigating the space </Text>

       </Row>



        <Row  style={{backgroundColor:"	#F5F5F5", borderRadius:"15px", }}>
        {screenWidth <= 450  ? (<>
        
        

<Col className='mobileUI'   xs={12} sm={12} md={8} lg={5}  style={{padding:"5px",}}
 
                 >
                  <Row  justify="center"  style={{  paddingTop:"10px",
               
                  }}>

                       <Col   xs={24} sm={24} md={10} lg={9} style={{alignItems: "center", display: "flex", justifyContent: "center",
                    
                    }}   >
                       <div  style={{width:"30px", 
height: "30px", 

}}>
              <img  src={Circle}  style={{
         
             width:"30px",
             height: "30px", 
       
              }} alt="fireSpot"/>
                       </div>
                       </Col>


                  </Row>

                  <Row justify="center"  style={{padding:"6px",}} >
                
                 <Text style={{fontSize:"10px", fontWeight: 'bold'}}> Move </Text>
                 
                 </Row>
</Col>

        



<Col  xs={12} sm={12} md={8} lg={5}  style={{ width:"100%", padding:"5px",}}> 
     


<Row  justify="center" style={{paddingTop:"10px",}}>
                
                <Col   xs={24} sm={24} md={10} lg={9} style={{alignItems: "center", display: "flex", justifyContent: "center", }}   >
                       <div  style={{width:"30px", height: "30px",}}>
<img  src={Tap}  style={{width:"30px",height: "30px", }} alt="fireSpot"/>
             </div>
</Col>
</Row>



                  <Row justify="center" 
                  style={{padding:"6px",}}>
     

               <Text style={{fontSize:"10px", fontWeight: 'bold'}}> Interact</Text>
     
                   </Row>

           </Col>



           <Col xs={12} sm={12} md={8} lg={5}   style={{ width:"100%", padding:"5px", }}>
            
           <Row   justify="center" style={{ height:"60px", }} >

 


<Col   xs={24} sm={24} md={10} lg={9} style={{alignItems: "center", display: "flex", justifyContent: "center", }}   >
                       <div  style={{width:"30px", height: "30px", }}>
<img  src={Person}  style={{height: "40px",}} alt="fireSpot"/>
 
 </div>
                </Col>


                  </Row>

                  <Row justify="center" style={{paddingBottom:"5px"}}>
                
                 <Text style={{fontSize:"10px", fontWeight: 'bold',}}>Jump</Text>
                 
                    

                   </Row>
                   
           </Col>
       


            <Col xs={12} sm={12} md={8} lg={9}  style={{ padding:"5px",}}>
            

            <Row  justify="center"  >
                
                <Col   xs={24} sm={24} md={10} lg={6} 
                style={{
                
                 height:"60px",
        
                }}>
      
     <Lottie 
     options={defaultOptions}
       height={45}
       width={45}
     
     />

   </Col> 

   </Row>



             
<Row justify="center" style={{ paddingBottom:"5px"}}>
  

      <Text style={{fontSize:"10px", fontWeight: 'bold'}}> Click & drag to rotate camera </Text>
                      
       </Row>


</Col>

 
        </>):  isLandscape2 && screenWidth <= 1050 ?(<>
        
        

<Col xs={12} sm={12} md={12} lg={5}  style={{padding:"5px",}}>
                  <Row  justify="center"  style={{  paddingTop:"10px",}}>

                       <Col   xs={24} sm={24} md={10} lg={9} style={{alignItems: "center", display: "flex", justifyContent: "center", }}   >
                       <div  style={{width:"30px", height: "30px", }}>
              <img  src={Circle}  style={{width:"30px",height: "30px",}} alt="fireSpot"/>
                       </div>
                       </Col>


                  </Row>

                  <Row justify="center"  style={{padding:"6px",}} >
                
                 <Text style={{fontSize:"10px", fontWeight: 'bold'}}> Move </Text>
                 
                 </Row>
</Col>

        



<Col  xs={12} sm={12} md={12} lg={5}  style={{ width:"100%", padding:"5px",}}> 
     


<Row  justify="center" style={{paddingTop:"10px",}}>
                
                <Col   xs={24} sm={24} md={10} lg={9} style={{alignItems: "center", display: "flex", justifyContent: "center",}}   >
                       <div  style={{width:"30px", height: "30px",}}>
<img  src={Tap}  style={{
 
width:"30px",
height: "30px", 

}} alt="fireSpot"/>
             </div>
</Col>
</Row>



                  <Row justify="center"  style={{padding:"6px",}}>
               

               <Text style={{fontSize:"10px", fontWeight: 'bold'}}> Interact</Text>
     
                   </Row>

           </Col>



           <Col xs={12} sm={12} md={12} lg={5}   style={{ width:"100%",  padding:"5px" }}>
            
           <Row   justify="center" style={{ height:"60px", }} >



<Col   xs={24} sm={24} md={10} lg={9} style={{alignItems: "center", display: "flex", justifyContent: "center", }}   >
                       <div  style={{width:"30px", height: "30px", }}>
<img  src={Person}  style={{height: "40px", }} alt="fireSpot"/>
 
 </div>
                </Col>


                  </Row>

                  <Row justify="center" style={{paddingBottom:"5px"}}>
             
                 <Text style={{fontSize:"10px", fontWeight: 'bold',}}>Jump</Text>
                 
                    

                   </Row>
                   
           </Col>
       


            <Col xs={12} sm={12} md={12} lg={9}  style={{ padding:"5px", }}>
            

            <Row  justify="center"  >
                
                <Col   xs={24} sm={24} md={10} lg={6} 
                style={{height:"60px", }}>
      
     <Lottie 
     options={defaultOptions}
       height={45}
       width={45}
   
     />

   </Col> 

   </Row>



             
<Row justify="center" style={{ paddingBottom:"5px"}}>
      

      <Text style={{fontSize:"10px", fontWeight: 'bold'}}> Click & drag to rotate camera </Text>
                      
       </Row>


</Col>




          </>):(<>








            <Col xs={12} sm={12} md={12} lg={6} className='colBox'>
         
<Row
justify="center" 
className='rowBox'
>  

  <Row  justify="center"  gutter={[7, 7]}  style={{width:"100%", }} >
<Col  xs={8} sm={6} md={4} lg={6} xl={4} style={{
 paddingTop:"6px",
   textAlign:"center"}}>
        <div className='my-custom-button'   >W</div>
        </Col>
      </Row>

      <Row justify="center"  gutter={[7, 7]}   style={{ width:"100%", padding:"6px" }}>
        <Col  xs={8} sm={6} md={4} lg={6} xl={4} style={{
        
          textAlign:"center"}}  >
          <div className="my-custom-button">A</div>
        </Col>
        <Col  xs={8} sm={6} md={4} lg={6} xl={4} style={{ 
         textAlign:"center"}}>
          <div className="my-custom-button">S</div>
        </Col>
        <Col  xs={8} sm={6} md={4} lg={6} xl={4} style={{ 
        textAlign:"center" }}>
          <div className="my-custom-button">D</div>
        </Col>
      </Row>


      </Row>
        

   



     
            <Row justify="center" 
             >
             <h5>Move</h5>
             </Row>

     </Col>
           


     <Col  xs={12} sm={12} md={12} lg={6} className='colBox' > 
 

<Row 
justify="center" 
align="middle" 
className='rowBox'
style={{}}>  


<Col  xs={8} sm={6} md={4} lg={6} xl={7} style={{textAlign:"center"}}>
        <div className='my-custom-button'   >Shift</div>
        </Col>
  </Row>


                  <Row justify="center"  
                  >
                   <h5>Hold to run</h5>
                   </Row>

           </Col>







<Col  xs={12} sm={12} md={12} lg={6} className='colBox' > 
           
        
    <Row 
    justify="center" 
    align="middle" 
    className='rowBox'
    >  
    
    
    <Col  xs={8} sm={6} md={4} lg={10} xl={10} style={{textAlign:"center"}}>
            <div className='my-custom-button'   >Spacebar</div>
            </Col>
      </Row>
    
    
                      <Row justify="center" 
                      >
                      <h5>Jump</h5>
                       </Row>
    
               </Col>
    







            
     <Col  xs={12} sm={12} md={12} lg={6} className='colBox'> 
       
    
    <Row 
    justify="center" 
    align="middle" 
    className='rowBox'
    >  
    
    
    <Col  xs={8} sm={6} md={4} lg={6} xl={7} style={{ textAlign:"center", height:"60px",}}>
          

     <Lottie 
     options={defaultOptions}
     height={65}
     width={65}
     />




            </Col>
      </Row>
    
    
                      <Row justify="center" >
                       <h5> Click & drag to rotate camera</h5>
                       </Row>
    
               </Col>  




          </>)}




        </Row>




<Row  >
            
            <Col  xs={24}  sm={24} lg={24}  md={24}  style={{paddingTop:"1%",}} 
             >
            <Row  justify="left"  >
     

<Text style={{fontSize:"20px", fontWeight: 'bold'}}>Other Controls</Text>
         
                </Row>
                 
                    <Row  
     
     xs={24}  sm={24} lg={24}  md={24}
     style={{backgroundColor:"	#F5F5F5", borderRadius:"15px"}}
     
            >
   {data.map((item, index) => (
 
 <Col 
 key={index}

 xs={12} sm={12} md={8} lg={4} xl={4} xxl={3}
 style={{width:"100%", padding:"10px",}}
 >

   <Row xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} justify="center"  style={{ padding:"4px",}}>
   
             {item.icon}     
                  
                   
       </Row>  

       <Row xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  justify="center"  >
   
       <Text style={{fontSize:"10px", fontWeight: 'bold'}}> {item.title}</Text>
       </Row>  
  
  </Col  >
   ))}
  </Row>

             </Col>

 </Row>            





          <br/>

                <Row justify="center" >
         
                    <Col  lg={24} md={24} style={{textAlign:"center", 
              
                    }} >
              
<Text style={{fontSize:"15px", fontWeight: 'bold'}}> Need more help?<a href="https://infinityvoidmetaverse.atlassian.net/servicedesk/customer/portals" style={{ textDecoration: 'underline' }}  target="_blank"> Check out our support page! </a></Text>



                    </Col>
              
                </Row>

               
</div>

        </>
    );
}

export default HelpUI;
