import React, { useState, useEffect } from 'react';

import { Spin, Switch,Image,Space,Typography,Col,Card, Divider, Row, Button  } from 'antd';
import axios from 'axios';

const Text = Typography;

const { Meta } = Card;



export default function DiscoverComponent({ParentCallback,newUnitSceneData,userId}){
   
  console.log(newUnitSceneData);


  

const handleVisit = (data)=>{


  var obj = "userId="+userId+"&"+"newdata="+"&"+"projectId="+data.sceneDataNew.projectId+"&"+"sceneId="+data.sceneDataNew.id+"&"+"plotId="+data._id+"&"+"unrealocation="+data.unreaLocation+"&"+"unreaRotation="+data.unreaRotation+"&"+"networkIp="+data.deployedScene.networkIp;

  /*  var obj = {
        name:data.name,
        unreaLocation:data.unreaLocation,
        unreaRotation:data.unreaRotation
      }
      var ele = JSON.stringify(obj);
    console.log(ele);
    */
    
    
    ParentCallback(obj);
}

  

    return(

<div style={{marginBottom:"10%"}}>
<Text className='discoverHead'>Explore Land Parcels</Text>



<br/>
      
            
            <Row
            className='disoverRow'
           
          >
    
    {newUnitSceneData && newUnitSceneData.map((data) => (
        <>

        
         <Col  key={data.id} xs={24} lg={6} md={12} style={{marginTop:"3%"}}>
    <Card className='discoverCard'  >
        
    
        
          
              <img
                alt= {data.name}
               className="discoverCardImage"
                src={data.sceneDataNew.sceneFileThreeD}
              />
           
         
            <Row className='cardDescRow'>
                <Col xs={24} lg={24} md={24} style={{textAlign:"start"}}>
                <Text className="cardName">
                {data.name}
              </Text>
              <Text className="cardDesc" >
                {data.description}
              </Text>
                </Col>

             
           
               
              
            </Row>

                  
            <Row className='cardDescRowVisit'>
                <Col xs={12} lg={12} md={12} style={{textAlign:"start"}}>
               
                </Col>

                <Col xs={12} lg={12} md={12} style={{textAlign:"end"}}>
                <Button onClick={()=>handleVisit(data)} className='visitBtn' >
               Visit 
              </Button>
                </Col>
           
               
              
            </Row>
           
      
        </Card>
        </Col>
         </>
        ))}
    
        
          </Row>

           
            </div>
    )
}