import { useEffect, useState} from 'react';
import { MapContainer,GeoJSON, TileLayer,Marker,  FeatureGroup, ZoomControl, LayerGroup, } from 'react-leaflet';
import "leaflet-rotate";
import axios from 'axios';
import { Col, Modal,  Row ,Button ,Select,Typography, Divider,Space,Spin,notification,message, Drawer} from 'antd';
import L from 'leaflet';
import MapIcon from '../assets/mapIcon.svg';
import "leaflet-imageoverlay-rotated";



import { AlignLeftOutlined, BorderOutlined, CloseOutlined } from  '@ant-design/icons'


import {  SearchOutlined } from '@ant-design/icons';

import { LoadingOutlined } from '@ant-design/icons';




import { FaTwitter, FaInstagram, FaCopy } from "react-icons/fa";

const { Text, Link } = Typography;





const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color:"#0A0519"
      
    }}
    spin
  />
);








let defaultCenter = [-55.355743088070334,-130.00136368835868];


const mapIcon = new L.Icon({
  iconUrl: MapIcon,
  //iconRetinaUrl: 'https://i.ibb.co/sJrMTdz/favicon-32x32.png',
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  
 iconSize: new L.Point(60, 75),
  className: 'leaflet-div-icon'
});


function MapComponent({ParentCallback}) {

  const [map, setMap] = useState(null);
  const [geoMapData, setGeoMapData]=useState([]);
 const[marker, setMarker]= useState([]);
 const [options, setOptions] = useState([]);
 const [value, setValue] = useState();
 const [activeLayer, setActiveLayer] = useState();
 const [roofStyle, setRoofStyle] = useState(true);
 const [showMintPop, setShowMintPop] = useState(false);
 const [LoadingScreen, setLoadingScreen] = useState(false);
 const[mintPrice, setMintPrice] = useState();
 const [mallActive, setMallActive] = useState(false);
 const[unitProperties, setUnitProperties] = useState();
 const[currentUnit, setcurrentUnit] = useState();
 const[activeGeoFeature, seActiveGeoFeature] = useState();

 const [tokenId, setTokenId] = useState();
 const [tokenUrl, setTokenUrl] = useState();

 const [currentFeatureOwner, setCurrentFeatureOwner] = useState();

 const[mapUrl, setMapUrl] = useState();


 const [openDetail, setOpenDetail] = useState(false);

 const[plotType, setPlotType] = useState("default");

 const [messageApi, contextHolder] = message.useMessage();
 

 const mapCenter=(position)=>{
if(position == "default"){
  defaultCenter = [-55.355743088070334,-130.00136368835868];
  map.setView([-55.355743088070334, -130.00136368835868]);
  setPlotType("default");
}else{
  defaultCenter = [-65.53583556874186,109.55091293877341];
  map.setView([-65.53583556874186, 109.55091293877341]);
  setPlotType("ultra");
}
 }

    


  

 const showDrawer = () => {
   setOpenDetail(true);
   
  
 };
 const onClose = () => {
   setOpenDetail(false);
  
 };

  
  
  {/* Toggle Side Bar */}

  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
      isOpen === true ? setIsopen(false) : setIsopen(true);
  }

       



  

useEffect(()=>{

  
loadData();

},[]);
  

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);


const loadData = async()=>{

  setLoadingScreen(true);
  const preLayerData =  await axios.get('https://mint.infinityvoid.io/gameApi/mapfeature');

setGeoMapData(preLayerData.data);
await delay(50);
setLoadingScreen(false);



  }





useEffect(()=>{
  
  if (!map) return;

  const bounds = map.getBounds();


 

  var southWest = L.latLng(-71.355743088070334,-155.00136368835868);
  var northEast = L.latLng(71.355743088070334, 155.00136368835868);
  var mybounds = L.latLngBounds(southWest, northEast);
  map.setMaxBounds(mybounds);

  map.rotateControl.setPosition('topright');
  
  var myIcon = L.icon({
    iconUrl: 'https://i.ibb.co/sJrMTdz/favicon-32x32.png',
    iconSize: [38, 95],
    iconAnchor: [22, 94],
    popupAnchor: [-3, -76],
    shadowUrl: 'my-icon-shadow.png',
    shadowSize: [68, 95],
    shadowAnchor: [22, 94]
});

    L.Icon.Default = L.icon({
      iconUrl: 'https://i.ibb.co/sJrMTdz/favicon-32x32.png',
      iconSize: [38, 95],
      iconAnchor: [22, 94],
      popupAnchor: [-3, -76],
      shadowUrl: 'my-icon-shadow.png',
      shadowSize: [68, 95],
      shadowAnchor: [22, 94]
  });


  map.on('click', (e) => {
    
  //map.setView(e.latlng ,9);
 // window.history.pushState({}, "", `?lat=${e.latlng.lat}&lng=${e.latlng.lng}`)
  //map.flyTo(e.latlng, 9);
   if(e.latlng.lat > -50.048669 && e.latlng.lat < -47.123972 && e.latlng.lng > -134.505638 && e.latlng.lng < -131.765614 ){
    map.setBearing(95);
   // map.setView(e.latlng ,9)
    
    if(roofStyle == true){
      const { lat, lng } = e.latlng;
      setMallActive(true);
      setRoofStyle(false);
      setActiveLayer('G');
     
      
    } 

    if(roofStyle == false){
   
     setMallActive(true);
     setRoofStyle(false);
     setActiveLayer(activeLayer);
    
    
    } 

   }
   else{
    setMallActive(false);
    setRoofStyle(true);
    map.setBearing(1);
  }
   })
  



},)


  
const ShowMarkers = ({ mapContainer, legend, markers }) => {
  return markers.map((marker, index) => {
    return <>
    <Marker
      key={index}
     // icon={ mapIcon }
      uniceid={index}
      position={marker}
      draggable={false}
      eventHandlers={{
        moveend(e) {
          const { lat, lng } = e.target.getLatLng();
          legend.textContent = `change position: ${lat} ${lng}`;
        }
      }}
    >

      

    </Marker>
    
    </>
  })
}


const  FlyToButton=async(latlng, feature)=> {
  if(!map) return;
  const { lat, lng } = latlng;
  
  setMarker([ [lat, lng]]);

  const activeUnit =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterInventory/${feature.properties.unitId}`);

  setcurrentUnit(activeUnit.data);
 
   if(activeUnit.data.mintPrice == undefined){
    setMintPrice("0.22");
    }
    else{
      setMintPrice(activeUnit.data.mintPrice);
    }


   setIsopen(true);
 
  setOpenDetail(true);
  
   
  
  setUnitProperties(feature.properties);
  seActiveGeoFeature(feature);

  const currentOwner =  await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${activeUnit.data.currentOwner}`);

  setCurrentFeatureOwner(currentOwner.data);
 }

 
  const handleActiveLayer = async (value) => {

    
    setMallActive(true);
  setRoofStyle(false);
 setActiveLayer(value);
 }


 


const handleSearch = (newValue) => {


  
 var data = geoMapData.features;
 let data1=[];

 for(let i=0;i<data.length;i++){

  if(data[i].properties.name && data[i].properties.name.includes(newValue.toUpperCase())){
   
    const obj = {
      value:data[i],
      name:data[i].properties.name
    }
    data1.push(obj);
  }
 }

 setOptions(data1);

};

const handleChange = async(newValue) => {
setValue(newValue);
  var selectedItemArray = options.filter(e=> e.name == newValue);
  var selectedItem = selectedItemArray[0];

  var corners = selectedItem.value.geometry.coordinates[0];


  seActiveGeoFeature(selectedItem.value);

  let lngArray=[];
  let latArray=[];
  
  
  var corner0 = corners[0];
  lngArray.push(corner0[0]);
  latArray.push(corner0[1]);
  
  var corner1 = corners[1];
  lngArray.push(corner1[0]);
  latArray.push(corner1[1]);
  
  var corner2 = corners[2];
  lngArray.push(corner2[0]);
  latArray.push(corner2[1]);
  
  var corner3 = corners[3];
  lngArray.push(corner3[0]);
  latArray.push(corner3[1]);
  
  var corner4 = corners[4];
  lngArray.push(corner4[0]);
  latArray.push(corner4[1]);
  
  
  lngArray.sort(compareNumbers);
  latArray.sort(compareNumbers);


  var lng = (lngArray[0]+lngArray[lngArray.length-1])/2;

  var lat = (latArray[0]+latArray[latArray.length-1])/2;


 // window.history.pushState({}, "", `?lat=${lat}&lng=${lng}`)


setUnitProperties(selectedItem.value.properties);


if(selectedItem.value.properties.category == "Mall"){
  setMallActive(true);
  setRoofStyle(false);
  
  if(selectedItem.value.properties.floor == "Ground"){
    setActiveLayer('G');
  }
  if(selectedItem.value.properties.floor == "First"){
    setActiveLayer('1');
  }
  if(selectedItem.value.properties.floor == "Second"){
    setActiveLayer('2');
  }
  if(selectedItem.value.properties.floor == "Third"){
    setActiveLayer('3');
  }
  if(selectedItem.value.properties.floor == "Fourth"){
    setActiveLayer('4');
  }
  
  }else{
    setMallActive(false);
    setRoofStyle(true);
    setActiveLayer('G');
  }

  setMarker([[ lat, lng]]);
  map.flyTo([lat, lng] ,9)

  const activeUnit =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterInventory/${selectedItem.value.properties.unitId}`);



  setcurrentUnit(activeUnit.data);
  setIsopen(true);
 

  if(activeUnit.data.mintPrice == undefined){
    setMintPrice("0.22");
    }
    else{
      setMintPrice(activeUnit.data.mintPrice);
    }

  setOpenDetail(true);
  const currentOwner =  await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${activeUnit.data.currentOwner}`);

  setCurrentFeatureOwner(currentOwner.data);



};

function compareNumbers(a, b) {
  return a - b;
}


const handleOverlayImage=(feature)=>{
var corners = feature.geometry.coordinates[0];


let lngArray=[];
let latArray=[];


var corner0 = corners[0];
lngArray.push(corner0[0]);
latArray.push(corner0[1]);

var corner1 = corners[1];
lngArray.push(corner1[0]);
latArray.push(corner1[1]);

var corner2 = corners[2];
lngArray.push(corner2[0]);
latArray.push(corner2[1]);

var corner3 = corners[3];
lngArray.push(corner3[0]);
latArray.push(corner3[1]);

var corner4 = corners[4];
lngArray.push(corner4[0]);
latArray.push(corner4[1]);


lngArray.sort(compareNumbers);
latArray.sort(compareNumbers);



if(feature.properties.shape && feature.properties.shape == 'A')

{

var topleft    = L.latLng(corner1[1], corner1[0]);
var	topright   = L.latLng(corner2[1], corner2[0]);
var	bottomleft = L.latLng(corner0[1], corner0[0]);
L.imageOverlay.rotated(feature.properties.shopImageUrl, topleft, topright, bottomleft, {
	opacity: 12,
	interactive: true,
	
}).addTo(map);
}

if(feature.properties.shape &&  feature.properties.shape == 'C'){


 topleft    = L.latLng(corner3[1], corner3[0]);
	topright   = L.latLng(corner2[1], corner2[0]);
	bottomleft = L.latLng(corner0[1], corner0[0]);
 L.imageOverlay.rotated(feature.properties.shopImageUrl, topleft, topright, bottomleft, {
    opacity: 12,
    interactive: true,
    
  }).addTo(map);
}




}




 
 



const jumpNft=()=>{

 

  var obj = {
    name:currentUnit.name,
    unreaLocation:currentUnit.unreaLocation,
    unreaRotation:currentUnit.unreaRotation
  }
  var ele = JSON.stringify(obj);
console.log(ele);

//var ele1 = JSON.parse(ele);
//console.log(ele1);

ParentCallback(obj);
}

const handleCloseMap=()=>{
  ParentCallback("closeMap");
}




  return (


<>

{contextHolder}

      
<Spin style={{marginTop:'15%', marginBottom:'15%', zIndex:"400"}} spinning={LoadingScreen} indicator={antIcon} delay={14} size={'large'}>
 



<MapContainer center={defaultCenter} 
  ref={setMap}
 
maxZoom={10}
minZoom={3}
zoomControl={false}
scrollWheelZoom={true}
attributionControl={false}
zoom={5}
rotate={true}
bearing={0.001}
touchRotate={true}

>
<TileLayer
          url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/1NTiles/{z}/{y}/{x}.png?format=webp"
          noWrap={true}
          maxNativeZoom={9}

        />

<TileLayer
          url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/2NTiles/{z}/{y}/{x}.png?format=webp"
          noWrap={true}
          maxNativeZoom={9}

        />

<TileLayer
          url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/3NTiles/{z}/{y}/{x}.png?format=webp"
          noWrap={true}
          maxNativeZoom={9}

        />

<TileLayer
          url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/4NTiles/{z}/{y}/{x}.png?format=webp"
          noWrap={true}
          maxNativeZoom={9}

        />
        <TileLayer
          url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/5NTiles/{z}/{y}/{x}.png?format=webp"
          noWrap={true}
          maxNativeZoom={9}

        />

<TileLayer
          url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/6NTiles/{z}/{y}/{x}.png?format=webp"
          noWrap={true}
          maxNativeZoom={9}

        />



<TileLayer
          url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/A2Base/{z}/{y}/{x}.png?format=webp"
          noWrap={true}
          maxNativeZoom={9}

        />


<TileLayer
          url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/a20/{z}/{y}/{x}.png?format=webp"
          noWrap={true}
          maxNativeZoom={9}

        />


{roofStyle == true ?(
  <TileLayer
  url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/mallroof/{z}/{y}/{x}.png?format=webp"
  noWrap={true}
  maxNativeZoom={9}

/>
)

:null}








<ZoomControl position="bottomright" />
  
{marker.length > 0  ? (
    <ShowMarkers
      mapContainer={map}
      markers={marker} 
     />
  )
    : (<></> )} 


   
                           
<FeatureGroup>
  {geoMapData.features && geoMapData.features.map((feature, i) => {



let  featureNewstyle =
{ stroke: true,
  color: "#0A0519",
  weight: 0.2,
  fillColor: "#FC92F1",
  fillOpacity:1,
  vectorEffect:'non-scaling-stroke'
};



if(!feature.properties.owner){
featureNewstyle =
 { stroke: true,
   color: "#0A0519",
   weight: 0.2,
   fillColor: "#26F8FF",
   fillOpacity:1,
  vectorEffect:'non-scaling-stroke'};
}

if(feature.properties.forReserve == true){
  featureNewstyle =
   { stroke: true,
     color: "#0A0519",
     weight: 0.2,
     fillColor: "#827f82",
     fillOpacity:1,
    vectorEffect:'non-scaling-stroke'};
  }


                return (
<>
                  {feature.properties.floor == "Land" ? (

                    <GeoJSON
                    key={i}
                    data={feature}
                    eventHandlers={{
                    click: (e) =>{
                    FlyToButton(e.latlng ,feature)
                      },
                   }}
                   style={featureNewstyle}
                  >
              
              {feature.properties.shopImageUrl !== undefined ? (
  
                handleOverlayImage(feature)
                
              ):(<></>) }
    
    
                  </GeoJSON>
                  ):(null)}
               </>
                );
              })}
                 
          </FeatureGroup>  
                  
              
{mallActive == true ? (
  <>

{activeLayer == 'G'?(

<LayerGroup  >

 
 <FeatureGroup>
{geoMapData.features && geoMapData.features.map((feature, i) => {



let  featureNewstyle =
{ stroke: true,
color: "#0A0519",
weight: 0.2,
fillColor: "#FC92F1",
fillOpacity:1,
vectorEffect:'non-scaling-stroke'
};



if(!feature.properties.owner){
featureNewstyle =
{ stroke: true,
 color: "#0A0519",
 weight: 0.2,
 fillColor: "#26F8FF",
 fillOpacity:1,
vectorEffect:'non-scaling-stroke'};
}


if(feature.properties.forReserve == true){
  featureNewstyle =
   { stroke: true,
     color: "#0A0519",
     weight: 0.2,
     fillColor: "#827f82",
     fillOpacity:1,
    vectorEffect:'non-scaling-stroke'};
  }

              return (
<>
                {feature.properties.floor == "Ground" ? (

                  <GeoJSON
                  key={i}
                  data={feature}
                  eventHandlers={{
                  click: (e) =>{
                  FlyToButton(e.latlng ,feature)
                    },
                 }}
                 style={featureNewstyle}
                >
            
            {feature.properties.shopImageUrl !== undefined ? (

              handleOverlayImage(feature)
              
            ):(<></>) }
  
  
                </GeoJSON>
                ):(null)}
             </>
              );
            })}
               
        </FeatureGroup> 

  </LayerGroup>


):null}



{activeLayer == '1'?(

  <LayerGroup  >
   <TileLayer
      url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/mallff/{z}/{y}/{x}.png?format=webp"
      noWrap={true}
      maxNativeZoom={9}

    />
   
   <FeatureGroup>
  {geoMapData.features && geoMapData.features.map((feature, i) => {



let  featureNewstyle =
{ stroke: true,
  color: "#0A0519",
  weight: 0.2,
  fillColor: "#FC92F1",
  fillOpacity:1,
  vectorEffect:'non-scaling-stroke'
};



if(!feature.properties.owner){
featureNewstyle =
 { stroke: true,
   color: "#0A0519",
   weight: 0.2,
   fillColor: "#26F8FF",
   fillOpacity:1,
  vectorEffect:'non-scaling-stroke'};
}

if(feature.properties.forReserve == true){
  featureNewstyle =
   { stroke: true,
     color: "#0A0519",
     weight: 0.2,
     fillColor: "#827f82",
     fillOpacity:1,
    vectorEffect:'non-scaling-stroke'};
  }


                return (
<>
                  {feature.properties.floor == "First" ? (

                    <GeoJSON
                    key={i}
                    data={feature}
                    eventHandlers={{
                    click: (e) =>{
                    FlyToButton(e.latlng ,feature)
                      },
                   }}
                   style={featureNewstyle}
                  >
              
              {feature.properties.shopImageUrl !== undefined ? (
  
                handleOverlayImage(feature)
                
              ):(<></>) }
    
    
                  </GeoJSON>
                  ):(null)}
               </>
                );
              })}
                 
          </FeatureGroup> 

    </LayerGroup>


):null}


{activeLayer == '2'?(

  <LayerGroup  >
   <TileLayer
      url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/mallsf/{z}/{y}/{x}.png?format=webp"
      noWrap={true}
      maxNativeZoom={9}

    />
   
   <FeatureGroup>
  {geoMapData.features && geoMapData.features.map((feature, i) => {



let  featureNewstyle =
{ stroke: true,
  color: "#0A0519",
  weight: 0.2,
  fillColor: "#FC92F1",
  fillOpacity:1,
  vectorEffect:'non-scaling-stroke'
};



if(!feature.properties.owner){
featureNewstyle =
 { stroke: true,
   color: "#0A0519",
   weight: 0.2,
   fillColor: "#26F8FF",
   fillOpacity:1,
  vectorEffect:'non-scaling-stroke'};
}

if(feature.properties.forReserve == true){
  featureNewstyle =
   { stroke: true,
     color: "#0A0519",
     weight: 0.2,
     fillColor: "#827f82",
     fillOpacity:1,
    vectorEffect:'non-scaling-stroke'};
  }

                return (
<>
                  {feature.properties.floor == "Second" ? (

                    <GeoJSON
                    key={i}
                    data={feature}
                    eventHandlers={{
                    click: (e) =>{
                    FlyToButton(e.latlng ,feature)
                      },
                   }}
                   style={featureNewstyle}
                  >
              
              {feature.properties.shopImageUrl !== undefined ? (
  
                handleOverlayImage(feature)
                
              ):(<></>) }
    
    
                  </GeoJSON>
                  ):(null)}
               </>
                );
              })}
                 
          </FeatureGroup> 
    </LayerGroup>


):null}

{activeLayer == '3'?(
 
  <LayerGroup  >
   <TileLayer
      url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/TFMALL/{z}/{y}/{x}.png?format=webp"
      noWrap={true}
      maxNativeZoom={9}

    />
   
   <FeatureGroup>
  {geoMapData.features && geoMapData.features.map((feature, i) => {



let  featureNewstyle =
{ stroke: true,
  color: "#0A0519",
  weight: 0.2,
  fillColor: "#FC92F1",
  fillOpacity:1,
  vectorEffect:'non-scaling-stroke'
};



if(!feature.properties.owner){
featureNewstyle =
 { stroke: true,
   color: "#0A0519",
   weight: 0.2,
   fillColor: "#26F8FF",
   fillOpacity:1,
  vectorEffect:'non-scaling-stroke'};
}

if(feature.properties.forReserve == true){
  featureNewstyle =
   { stroke: true,
     color: "#0A0519",
     weight: 0.2,
     fillColor: "#827f82",
     fillOpacity:1,
    vectorEffect:'non-scaling-stroke'};
  }

                return (
<>
                  {feature.properties.floor == "Third" ? (

                    <GeoJSON
                    key={i}
                    data={feature}
                    eventHandlers={{
                    click: (e) =>{
                    FlyToButton(e.latlng ,feature)
                      },
                   }}
                   style={featureNewstyle}
                  >
              
              {feature.properties.shopImageUrl !== undefined ? (
  
                handleOverlayImage(feature)
                
              ):(<></>) }
    
    
                  </GeoJSON>
                  ):(null)}
               </>
                );
              })}
                 
          </FeatureGroup> 
    </LayerGroup>


):null}

{activeLayer == '4'?(
  <LayerGroup  >
   <TileLayer
      url="https://vaikunth-map.fra1.cdn.digitaloceanspaces.com/mallforthfloor/{z}/{y}/{x}.png?format=webp"
      noWrap={true}
      maxNativeZoom={9}

    />
   
   <FeatureGroup>
  {geoMapData.features && geoMapData.features.map((feature, i) => {



let  featureNewstyle =
{ stroke: true,
  color: "#0A0519",
  weight: 0.2,
  fillColor: "#FC92F1",
  fillOpacity:1,
  vectorEffect:'non-scaling-stroke'
};



if(!feature.properties.owner){
featureNewstyle =
 { stroke: true,
   color: "#0A0519",
   weight: 0.2,
   fillColor: "#26F8FF",
   fillOpacity:1,
  vectorEffect:'non-scaling-stroke'};
}

if(feature.properties.forReserve == true){
  featureNewstyle =
   { stroke: true,
     color: "#0A0519",
     weight: 0.2,
     fillColor: "#c5c5c5",
     fillOpacity:1,
    vectorEffect:'non-scaling-stroke'};
  }

                return (
<>
                  {feature.properties.floor == "Fourth" ? (

                    <GeoJSON
                    key={i}
                    data={feature}
                    eventHandlers={{
                    click: (e) =>{
                    FlyToButton(e.latlng ,feature)
                      },
                   }}
                   style={featureNewstyle}
                  >
              
              {feature.properties.shopImageUrl !== undefined ? (
  
                handleOverlayImage(feature)
                
              ):(<></>) }
    
    
                  </GeoJSON>
                  ):(null)}
               </>
                );
              })}
                 
          </FeatureGroup> 
    </LayerGroup>


):null}



</>
 


) : (
<></>
)}


        </MapContainer>
        
       
           <AlignLeftOutlined onClick={() => ToggleSidebar()}   style={{position:'absolute', top:'19px', left:'15px',color:"#000",fontSize:"20px", zIndex:'100'}} />
    



           <Drawer width={"400px"}closeIcon={<CloseOutlined style={{position:'absolute', top:'12px', left:'15px',color:"#26F8FF",fontSize:"20px", zIndex:'100'}}/>} closable={true} placement="left" mask={false} onClose={() =>ToggleSidebar()} open={isOpen} zIndex={0}>
       

       
        <div style={{ marginTop:'12px'}}>
       
 
                
                <Text style={{ fontWeight: '450', fontSize: '16px', color: '#26F8FF', }}>SEARCH</Text>     
     
      
                <Select
                  
                  style={{
                    marginTop:'2px',
                    width: 360,
                    background:'white',
                    borderRadius:'80px'
                  
                  
                  }}
                  listHeight={120}
                  showSearch
                  bordered={false}
                  suffixIcon={<SearchOutlined style={{color:'black'}} />} 
                  value={value}
                  
                  placeholder={"Search plots and names"}
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={false}
                  // onSelect={handleSelect(value)}
                  onSearch={handleSearch}
                  onChange={handleChange}
                  notFoundContent={null}
                  options={(options || []).map((d) => ({
                    value: d.name,
                    label: d.name,

                    
                  }))}
                  
                  getPopupContainer={trigger => trigger.parentElement}
                  
                  
              />

     



              
        </div>


              
          <Row  style={{marginTop:"40%"}} >
                <Text style={{ fontWeight: '450', fontSize: '16px', color: '#26F8FF', }}>KEY</Text>
          </Row>
            <br/>
              <Row > 
         

                 <Col span={6}>
                  <Space>

                  <BorderOutlined  style={{background:'#26F8FF'}} />
                <Text style={{color:"white"}}> Available </Text>

                  </Space>
               
                
                 </Col>

                 <Col span={6} offset={4}>
                 <Space>
                 <BorderOutlined style={{background:'#ef89de'}} />
                <Text style={{color:"white",textAlign:"right"}}> Sold  </Text>
                </Space>
                 </Col>


                 <Col span={6} offset={2}>
                 <Space>
                 <BorderOutlined style={{background:'#827f82'}} />
                <Text style={{color:"white"}}> Reserved </Text>
                </Space>
                 </Col>
                
                
               
                
               


              

              </Row>  



   
     {openDetail == false ? (
         <>
          </>) : (
            <>
              





              
              <Divider dashed={true} style={{border:'1px dashed white'}}/>
             



                    {currentUnit.category == "Land" ? (
                      <Row  >
                      <Text style={{color:'#26F8FF'}} >LAND DETAILS</Text>
                      </Row>
                    ) : (
                      <Row >  
                      <Text style={{color:'#26F8FF'}}>UNIT DETAILS</Text>
                      </Row>
                       )
                    }
   
            
      
      {currentUnit.category == "Land" ? (
                        <>
                          <Space.Compact>
                           
                        <Text style={{color:'white'}}>Parcel No. {currentUnit && currentUnit.name} </Text>
                        </Space.Compact>
                       
                    </>
                      ) : (
                          <>
                             <Space.Compact  >
                      <Text style={{color:'white'}}>Unit No. {currentUnit && currentUnit.name}, Infinty Mall & Metaplex </Text>
                           </Space.Compact>
                           
                      </>

                  
                    )
                      }
                      
                    {currentUnit.description !== undefined ? (
                       <Space.Compact  direction='vertical'>
                            <br/>
                        <Text style={{ color: 'white' }} > {currentUnit && currentUnit.description} </Text>
                     </Space.Compact> 
                      
                    ): (
                        null
              ) }
                  

                      {currentUnit.webUrl !== undefined ? (
                       <Space.Compact  direction='vertical'>
                           <br/>
                        <Text style={{color:'#26F8FF'}}>URL</Text> 
                        <Text  style={{color:"white"}}><a style={{color:'white'}} target="blank" href={currentUnit.webUrl} > {currentUnit && currentUnit.webUrl} </a></Text>
                     </Space.Compact> 
                      
                    ): (
                        null
              ) }


                    
              
                        
                  
                          <br/>
                          <br/>
                         
                    
                         <Space.Compact  direction='vertical'   >
                        <Text style={{color:'#26F8FF'}}>Area (sqm)</Text>
                         <Text  style={{color:"white"}}>{currentUnit && currentUnit.area}</Text>
                        </Space.Compact >
                    
                    
                          <br/>
                          <br/>

                          <Space.Compact  direction='vertical'   >
                        <Text style={{color:'#26F8FF'}}>Unit Area Category</Text>
                         <Text  style={{color:"white"}}>{currentUnit && currentUnit.inventoryCategory}</Text>
                        </Space.Compact >

                        <br/>
                         <br/>

                         <Space.Compact  direction='vertical'   >
                        <Text style={{color:'#26F8FF'}}>Category</Text>
                         <Text  style={{color:"white"}}>{currentUnit && currentUnit.category}</Text>
                        </Space.Compact >

                       

                   
                    
                        <br/>
                     <br/>

                     {unitProperties && unitProperties.forReserve == true?(
                 
<Space.Compact  direction='vertical'   >
<Button  type="primary"  onClick={() => jumpNft()}  >JUMP NOW</Button>
                </Space.Compact >
                     ):(
                      <>
                             {unitProperties && unitProperties.owner == null ?(
  
  <>

<Space.Compact  direction='vertical'   >
<Button  type="primary"  onClick={() => jumpNft()}  >JUMP NOW</Button>
                </Space.Compact >

           <br/>

  </>

) : (
<>
<Row>
<Text style={{color: '#26F8FF' }}>Owner</Text>

</Row>     

{currentFeatureOwner && currentFeatureOwner !== undefined ? (
                  <>
                    <Row >
                      <Col  span={19} >
                      {currentFeatureOwner.linkedENSName && currentFeatureOwner.linkedENSName.name !== undefined?(

                        <Text   style={{color:"white"}} >@{currentFeatureOwner.linkedENSName.name}</Text>
                      ):(
                        <Text  style={{ color: "white" }}>  

                        {currentFeatureOwner.walletAddress.substring(0, 4)}....{currentFeatureOwner.walletAddress.slice(38)} 

                      
                        </Text>
                      )}
                        
                      </Col>
                    
                      <Col  span={2}   >
                        
                        <a target="blank" href ={currentFeatureOwner.instagram}>
                        <FaInstagram 
                          style={{
                          color:"white", fontSize:"16px"
                        }} />
                        </a>
                        
                      </Col>
                        
                      
                      <Col  span={3}   >
                      
                      <a target="blank" href={currentFeatureOwner.twitter}>
                       <FaTwitter
                        
                        style={{
                          color:"white", fontSize:"16px"
                        }}
                          />
                       </a>   
                          </Col>
                    
                    
                    
                    </Row>
                    

                  <br/>
                  </>
                ) : (
                    <>
     
                       
                     

                      {currentUnit.currentOwner !== undefined?(
                         <Text  style={{ color: "white" }}>  
{currentUnit && currentUnit.currentOwner.substring(0, 4)}....{currentUnit && currentUnit.currentOwner.slice(38)} 


       
</Text>
                   
                      ):(
null
                      )}
                            
                      
                
                         
                      

                      <br />       
                      <br/>                              
                      </>
 )}


<Space.Compact  direction='vertical'>
<Button  type="primary"  onClick={() => jumpNft()}  >JUMP NOW</Button>
                </Space.Compact >

           <br/>


</>
          
 
)}
                      </>
                     )}
          
 



     

      </>
          )}

          

    </Drawer>
        
         
 

       
        

{mallActive == true ? (


<div style={{position:'absolute', bottom:'29%', right:'10px', zIndex:'100'}}>
            <Space size='small' direction='vertical'>

              
          

              {activeLayer == '4' ? (
                <>
                  
               
                    <Button style={{width:'40px',backgroundColor:"#26F8FF",height:"40px", borderRadius:"2px", border:"2px solid #0a0519", color:"#0a0519"}}  type="primary" size="middle" onClick={() => handleActiveLayer('4')}>
                      4
                    </Button>
               
           
                </>
              ) : (
                <>
                  
                    
                 
                    <Button type="primary"  style={{width:'40px',backgroundColor:"#000",height:"40px", borderRadius:"2px", border:"2px solid #26F8FF"}} size="middle" onClick={() => handleActiveLayer('4')}>
                      4
                    </Button>
               
           
                  
                </>
                  
              )
          
              }






              {activeLayer == '3' ? (
                <>
                  
                  
<Button type="primary" style={{width:'40px',backgroundColor:"#26F8FF",height:"40px", borderRadius:"2px", border:"2px solid #0a0519", color:"#0a0519"}} size="middle" onClick={()=>handleActiveLayer('3')}>
        3
</Button>

                </>
              ) : (
                  <>
                    
                   
<Button type="primary" style={{width:'40px',backgroundColor:"#000",height:"40px", borderRadius:"2px", border:"2px solid #26F8FF"}} size="middle" onClick={()=>handleActiveLayer('3')}>
        3
</Button>
 

                  </>
              )}

           
              



              {activeLayer == '2' ? (
                <>
              
<Button  type="primary"  style={{width:'40px',backgroundColor:"#26F8FF",height:"40px", borderRadius:"2px", border:"2px solid #0a0519", color:"#0a0519"}}   size="middle" onClick={()=>handleActiveLayer('2')}>
        2
</Button>

                </>
              ) : (
                <>


<Button  type="primary" style={{width:'40px',backgroundColor:"#000",height:"40px", borderRadius:"2px", border:"2px solid #26F8FF"}} size="middle" onClick={()=>handleActiveLayer('2')}>
        2
</Button>


                </>
              )}


              {activeLayer == '1' ? (
                <>
              
              
            
<Button type="primary"  style={{width:'40px',backgroundColor:"#26F8FF",height:"40px", borderRadius:"2px", border:"2px solid #0a0519", color:"#0a0519"}}  size="middle" onClick={()=>handleActiveLayer('1')}>
        1
</Button>

              
                </>) : (
                <>
                          
<Button type="primary"  style={{width:'40px',backgroundColor:"#000",height:"40px", borderRadius:"2px", border:"2px solid #26F8FF"}} size="middle" onClick={()=>handleActiveLayer('1')}>
        1
</Button>

                
                </>)}

              


              {activeLayer == 'G' ? (
                <>
                
                  

   
   <Button type="primary"   style={{width:'40px',backgroundColor:"#26F8FF",height:"40px", borderRadius:"2px", border:"2px solid #0a0519", color:"#0a0519"}}  size="middle" onClick={()=>handleActiveLayer('G')} >
           G
   </Button>


                </>)
                :
                (<>
                  
                  

   
   <Button type="primary"  style={{width:'40px',backgroundColor:"#000",height:"40px", borderRadius:"2px", border:"2px solid #26F8FF"}} size="middle" onClick={()=>handleActiveLayer('G')} >
           G
   </Button>
 


                </>)}


</Space>
</div>):null}

</Spin>
  </>

  );
}



export default MapComponent;
