import React, { useState, useEffect } from 'react';
import {useAccount,useConnect,useDisconnect } from 'wagmi'
import { Col,Card,  Radio,Space, Avatar, Row ,Button ,Form, Input,Typography} from 'antd';
import axios from 'axios';
import { useForm } from "react-hook-form";

const { Text } = Typography

function Login({parentCallback}){
    const { address, connector, isConnected } = useAccount()
    const { register, handleSubmit,  setValue, formState: { errors }   } = useForm({ mode: 'onBlur' });

    const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect()
  const { disconnect } = useDisconnect()
  
    const [form] = Form.useForm();
    const { Meta } = Card;
    const [currentUser,setCurrentUser] = useState();
    const[showCreateAccount,setShowCreateAccount] = useState(false);

    const[showGuestUi,setShowGuestUi] = useState(false);
    const[showSignUi,setShowSignUi] = useState(true);
    const [validated, setValidated] = useState(false);

const[userNameInput,setUserNameInput] = useState();


    useEffect(()=>{
      const userCall = async () => {
  

        var regionFetch = await axios.get('https://ipapi.co/json/');
        console.log(regionFetch.data.region);

        if (isConnected && address !== undefined) {
          var walletAdrees = address.toLowerCase();
       
          const userData =  await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${walletAdrees}`);
    
    
          if(userData.data.name == undefined){
            setShowCreateAccount(true);
           
          }else{

            Object.assign(userData.data, {region:regionFetch.data.region});
            console.log(userData.data);

            var url = `https://mint.infinityvoid.io/gameApi/gameuser/${userData.data.id}`;
            const formData = userData.data ;
            const response = await axios.put(url, formData)

              setCurrentUser(userData.data);
              parentCallback(userData.data);
          }
    
          }
    
      }
    
      userCall();
    
    },[isConnected, address])


     const handleSignUi =()=>{
        setShowGuestUi(false);
        setShowSignUi(true);
       }

       const handleGuestUi =()=>{
        setShowSignUi(false);
        setShowGuestUi(true);
       }

       const onWalletCreateUser = async(data) => {

    
          var regionFetch = await axios.get('https://ipapi.co/json/');
          console.log(regionFetch.data.region);
  
      

        Object.assign(data, {name:data.name.replace(/\s/g, "").toLowerCase()
      });
    
      const arr = data.fullName.split(" "); 
     for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
    
      Object.assign(data, {fullName:str2
    });

        
        try {
          var url = 'https://mint.infinityvoid.io/gameApi/gameuser';
          const formData = { name: data.name, region:regionFetch.data.region, fullName:data.fullName, email: data.email, walletAddress: address.toLowerCase() };
          const response = await axios.post(url, formData)
        
          setCurrentUser(response.data);
          parentCallback(response.data);
        } catch (error) {
          console.error(error);
        }

      };

       const onCreateGuestUser= async(data)=>{

           
        var regionFetch = await axios.get('https://ipapi.co/json/');
        console.log(regionFetch.data.region);

        console.log(data);
    
       var randomPIN = (Math.random()*9000)+1000;
       var newName = data.name+"#"+randomPIN.toFixed(0);;
       console.log(newName);                

        if(localStorage.getItem("id") == null){
          try {
                     var url = 'https://mint.infinityvoid.io/gameApi/gameuser';
                     const formData = { name: newName, region:regionFetch.data.region, email: data.email, walletAddress: "Guest" };
                     const response = await axios.post(url, formData)
                    
           localStorage.setItem("name", data.name);
           localStorage.setItem("email", response.data.email);
           localStorage.setItem("id", response.data.id);
           setCurrentUser(response.data);
         parentCallback(response.data);
                   } catch (error) {
                     console.error(error);
                   }
        }else{
          try {


        
                     var url = 'https://mint.infinityvoid.io/gameApi/gameuser/';
               var updateUrl = url+localStorage.getItem("id");
               
                     const formData = { name: newName, region:regionFetch.data.region, email: data.email };
                     const response = await axios.put(updateUrl, formData);

         if(response.data.name == undefined){
          var url = 'https://mint.infinityvoid.io/gameApi/gameuser';
          const formData = { name: newName, email: data.email, walletAddress: "Guest" };
          const response = await axios.post(url, formData)
         
localStorage.setItem("name", data.name);
localStorage.setItem("email", response.data.email);
localStorage.setItem("id", response.data.id);
setCurrentUser(response.data);
parentCallback(response.data);
         }else{
          localStorage.setItem("name", data.name);
          localStorage.setItem("email", response.data.email);
         setCurrentUser(response.data);
       parentCallback(response.data);
         }
                  
        
                  } catch (error) {
                     console.error(error);
                   }
         
        }

       }
    return(
        <>

<Row>
        <div style={{ position:"fixed",left:"20px"}}>
        <img src = "./infilogo.png" width="100px"style={{zIndex:"100"}} /> 
        </div >
</Row>
<br/><br/>
<br/><br/>
<br/><br/>
<Row style={{position:"fixed",width:"100vw",height:"90vh",overflowY:"auto"}}>
<Col xs={{flex:30,offset:3}} sm={{flex:5,offset:6}} lg={{flex:5,offset:10}} md={{flex:5,offset:8}}>

<Radio.Group defaultValue="clientUser" buttonStyle="solid" >
      <Radio.Button value="clientUser" onClick={()=>handleSignUi()}>SIGN IN</Radio.Button>
      <Radio.Button value="guestUser" onClick={()=>handleGuestUi()}>GUEST USER</Radio.Button>
</Radio.Group>


<br/>
<br/>

{showSignUi?(<>

{isConnected?(
<>
{showCreateAccount?(
<>

    <h3  style={{color:"#fff",fontSize:"16px",padding:"3%",textAlign:'center'}}>
  Create Account
</h3>

<Form autoComplete='off' form={form} onFinish={onWalletCreateUser}>


      <Form.Item  name="fullName"
           onChange={(event) => {
     
            const arr = event.target.value.split(" "); 
            for (var i = 0; i < arr.length; i++) {
             arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
             }
             const str2 = arr.join(" ");
                                      event.target.value = str2;
                                    }} 
        rules={[
          {
            required: true,
          }
        ]}>
        <Input   placeholder='Full Name' style={{background:"transparent",borderRadius:"20px",border:"2px solid #26F8FF", padding:"2%",color:"#fff"}}/>
      
      </Form.Item > 

      <Form.Item  name="name"
        rules={[
          {
            required: true,
            message:"Required" },
            {
            pattern: new RegExp(
              /^[a-z]+$/
            ),
            message:"Username must use only lowercase letters, without spaces, numbers or special characters." 
           
          },
          {
            validator: (_, value) =>
              !value.includes(" " || "")
                ? Promise.resolve()
                : Promise.reject(new Error("No spaces allowed"))
          }
        ]}>
        <Input    placeholder='UserName' style={{background:"transparent",borderRadius:"20px",border:"2px solid #26F8FF", padding:"2%",color:"#fff"}}/>
      
      </Form.Item >


            <Form.Item  name="email"
        rules={[
          {
            required: true,
          }
        ]}>
        <Input  placeholder='Email' type='email' style={{background:"transparent",borderRadius:"20px",border:"2px solid #26F8FF", padding:"2%",color:"#fff"}}/>
      
      </Form.Item >      

      <Button type="primary" htmlType="submit" style={{width:"100%",borderRadius:"20px"}}>
          Submit
        </Button>


</Form>

</>
  ):(null)}
</>
  ):(
    <>
      
    <Space  direction='vertical' align='center' style={{marginBottom:"8%"}}>
    <Text style={{color:"#26F8FF", fontSize:"18px", textAlign:"center"}}> 
Connect Wallet
</Text>

    <Text style={{color:"#26F8FF", fontSize:"14px", textAlign:"center"}}> 
Choose how you want to connect with network.
</Text>
</Space>
   
    {connectors.map((connector) => (
    <>
<Card   onClick={() => connect({ connector })} style={{ width: 300,padding:"10px"}} bodyStyle={{textAlign:"justify"}}>
      <Meta
    
        avatar={<Avatar size="large" src={connector.options.imageIcon} />}
        title={(
          <>
           {connector.options.appName}
  {!connector.ready && ' (unsupported)'}
        {isLoading &&
          connector.id === pendingConnector?.id &&
          ' (connecting)'}
          </>
         )}
        description={connector.options.appSub}
      />
    </Card>
     <br/>
     </>
    ))}
   

      </>
  
    )
  }

</>):(null)}  


{showGuestUi?(<>


    <h3  style={{color:"#fff",fontSize:"16px",padding:"3%",textAlign:'center'}}>
    Play as a guest without logging in.

</h3>

    <Form autoComplete='off' form={form} onFinish={onCreateGuestUser}>
<Form.Item initialValue={localStorage.getItem('name')}  name="name"
      
        rules={[
          {
            required: true,
            message:"Required" },
            {
            pattern: new RegExp(
              /^[a-z]+$/
            ),
            message:"Username must use only lowercase letters, without spaces, numbers or special characters." 
           
          },
          {
            validator: (_, value) =>
              !value.includes(" " || "")
                ? Promise.resolve()
                : Promise.reject(new Error("No spaces allowed"))
          }
        ]}>
        <Input  placeholder='Name' 
 style={{background:"transparent",borderRadius:"20px",border:"2px solid #26F8FF", padding:"2%",color:"#fff"}}/>
      
      </Form.Item >


            <Form.Item  initialValue={localStorage.getItem('email')} name="email"
        rules={[
          {
            required: true,
          }
        ]}>
        <Input    placeholder='Email' type='email' style={{background:"transparent",borderRadius:"20px",border:"2px solid #26F8FF", padding:"2%",color:"#fff"}}/>
      
      </Form.Item >      

      <Button type="primary" htmlType="submit" style={{width:"100%",borderRadius:"20px"}}>
          Submit
        </Button>


</Form>

</>
):(
    <>
    
    </>
)}
</Col>


</Row>
        </>
    )
}

export default Login;
