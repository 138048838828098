import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import axios from 'axios';
import {BsPlusCircle} from 'react-icons/bs';
import ReadyPlayerMeUI from './ReadyPlayerMeUI';
import { Spin, Switch,Image,Space,Typography } from 'antd';
import { after } from "underscore";

import "./avatarCard.css"





function AvatarUi({parentCallback,currentUser}) {

const [avatarData,setAvatarData] = useState([]);
const [open, setOpen] = useState(false);
const [loading,setLoading] = useState(true);


const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

useEffect(()=>{

const fetchAvatar=async()=>{


  const avatarDataList =  await axios.get(`https://mint.infinityvoid.io/gameApi/avatarData`)
 setAvatarData(avatarDataList.data.rows);
  console.log(avatarData);



}

  

fetchAvatar();
},[open]);


const createNewAvatar =()=>{
setOpen(true);
}


const AvatarSelectDone =async(avatar)=>{

  console.log(avatar);
if(avatar == "close"){
setOpen(false);
}else{

  let string = avatar; 
     

let newstring = string.replace(/.glb/, '.png'); 

console.log(newstring)


  var url = 'https://mint.infinityvoid.io/gameApi/avatarData';

  var formData = {
                      
    data:{
      fileUrl: avatar, userId:currentUser && currentUser.id, type:"user", thumbnailUrl:newstring
}
}

console.log(formData);
  try {
    const response = await axios.post(url, formData);

setOpen(false);
setLoading(true);
    
} catch (err) {
  
  console.log(err);
}
}


}

const AvatarSelectProceed =(data)=>{
parentCallback(data);
}

const onComplete=async() => {

  console.log("LOADING")
  await delay(4000).then(()=>{
    setLoading(false);
  })

  
  
};

    return (
        <div >
         

{open== false?(

<>


):(


<Row  style={{marginTop:"5%",marginBottom:"10%",zIndex:"1000",position:"fixed",height:"100vh",width:"100vw",overflowX:"hidden",overflowY:"auto"}}> 




      <Col 
   
      
      xs={{ span: 24 }}
      sm={{ span: 22, offset: 1 }}
     lg={{ span: 22, offset: 1 }}

      style={{ borderRadius:"15px"}}>
        

        <Row   >


   
         <Col     span={20} offset={2}>
        


       

      <Row     xs={24}  sm={24} lg={24}   md={24}  justify="center"  style={{width:"100%" }}>
            
            <h3 style={{color:"#fff"}}>Choose Avatar</h3>
           </Row>


   

   




           <Row  

        
         style={{
            //width:"100%",
            marginTop:"10px",  }}>
{loading?(
  <div  style={{height:"100vh",width:"100vw",textAlign:"center",position:"fixed",top:0,bottom:0,left:0,right:0,zIndex:"40",background:"black"}}>
       
  <div style={{marginTop:"20%"}}>

<Space size={'large'}>

<Spin spinning={loading} delay={500} size="large" />


</Space>
</div>
</div>
):(
  <>
  
  
  </>
)
}


  {avatarData && avatarData.filter(e=> e.type == "default").map((item, index) => (

   <Col 
   key={index}
lg={3} md={4} sm={4} xs={8}  className="cardBox" >

     <Card onClick={()=>AvatarSelectProceed(item.fileUrl)} bordered={false} className='cardAvt' 
     hoverable
  >
   <img key={item.id} alt={item.id}  onLoad={onComplete} 
         onError={onComplete}   className='imgAvt' src={item.thumbnailUrl} />
 

       
      
     </Card>
   </Col>

))}
    

    <Col 
   
lg={3} md={8} xs={8}  style={{padding:"2%"}} >


   <BsPlusCircle onClick={()=>createNewAvatar()} className='createBtn' />
 

       
      
    
   </Col>

  

   


</Row>



<hr/>





<Row     xs={24}  sm={24} lg={24}   md={24}  justify="left"  style={{width:"100%",}}>
            
            <h3 style={{color:"#fff"}}>Your Saved Avatars</h3>
           </Row>


         
           <Row  
     
    xs={24}  sm={24} lg={24}  md={24}
        
         style={{
            //width:"100%",
             marginTop:"10px",  }}>
  {avatarData && avatarData.filter(e=> e.type !== "default" && e.userId == currentUser.id).map((item, index) => (


<Col 
key={index}
lg={3} md={8} xs={8}  className="cardBox" >

<Card onClick={()=>AvatarSelectProceed(item.fileUrl)} bordered={false} className='cardAvt' 
     hoverable
  >
   <img key={item.id} alt={item.id}  onLoad={onComplete} 
         onError={onComplete}    className='imgAvt' src={item.thumbnailUrl} />
 

       
      
     </Card>
</Col>

))}
 

</Row>



      </Col>
      </Row>




      </Col>
    </Row>  

    </>
):(


      <ReadyPlayerMeUI parentCallback={AvatarSelectDone}/>
  

)}



        </div>

    );
}

export default AvatarUi;