import React, { useState, useEffect } from 'react';

import { Spin, Switch,Image,Space,Typography } from 'antd';

const Text = Typography;
export default function LoadingScreen({loadingMessage}){
    const [loading, setLoading] = useState(true);

 

    return(
        <div  style={{height:"100vh",width:"100vw",textAlign:"center",position:"fixed",top:0,bottom:0,left:0,right:0,zIndex:"40",background:"black"}}>
       
       <div style={{marginTop:"20%"}}>

        <Space size={'large'}>
       <Text  style={{color:"white", fontSize:"24px"}}>
    
        {loadingMessage}
        </Text>
        <Spin spinning={loading} delay={500} size="large" />

      
       </Space>
        </div>

        </div>
    )
}